<template>
<LoadingOverlay :show="beforeLoad"/>
  <whiteOverlay :show="beforeLoad"/>
  <main class="main_v8 books" :style="[edit ? {'padding-bottom': '44px'} : '']">
    <breadcrumb></breadcrumb>

    <h2 v-if="!draft" class="page_header" @click="here" :style="[edit  ? { paddingLeft: paddingLeft, paddingRight: paddingRight } : null]">
      <div class="delete_this_page" @click="deleteCurrent = true" v-if="edit && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('delete'))"></div>
      {{this.title}}
      <div class="edit_header_button" v-if="edit && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('edit'))" @click="titleEdit = true; editedToSave = 'sectionEdit'"></div>
    </h2>
    <h2 v-else class="page_header draft"  :style="[edit  ? { paddingLeft: paddingLeft, paddingRight: paddingRight } : null]">
      <div class="delete_this_page" @click="deleteCurrent = true" v-if="edit && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('delete'))"></div>
      Books in Drafts Section
      <div class="edit_header_button" v-if="edit && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('edit'))" @click="titleEdit = true; editedToSave = 'sectionEdit'"></div>
      <div class="books_draft" :class="[edit ? 'books_draft_edit' : null]">
        <h2>Create Now Drafts</h2>
        <p>This is where all of your Create Now Page drafts are initially located. </p>
        <svg></svg>
      </div>
    </h2>
    <!-- <div class="books_amount" v-text="`${sectionsAmount} books`" v-if="!edit"></div> -->
    
    <draggable
      v-model="books"
      ghost-class="ghost-card"
      @change="saveSortOrder"
      handle=".dragger"
      :animation="200"
      class="ghost"
    >
      <transition-group>
        <div class="menu_item"
          v-for="(book, index) in books"
          :key="book.id"
          @click="$router.push(book.route)"
          :class="[{'draggable' : edit}, {'collapsed' : this.$store.state.sectionCollapse}]"
        >   
        <transition name="totop_modal">
          <div v-if="editBook >= 0" class="updown book_info image_process" @click.stop :style="[imageWarn >= 0 || deleteCategory >= 0 || deleteCategoryCommit ? {zIndex: '1'} : null]">
            <!-- <span class="options_toggle" @click="universalSave()">{{ imgEdit ? 'IMAGE MENU' : 'BOOK EDIT' }}</span> -->
            <span class="options_toggle" @click="here()">{{ imgEdit ? 'IMAGE MENU' : 'BOOK EDIT' }}</span>

            <div class="tag">
              <p v-if="!imgEdit">Image</p>
              <transition v-if="imgEdit && editBookImg != 'url()'">
                <div style="height: 100%; width: 100%; background-size: cover; border-radius: 12px; position: relative; flex: 1; aspect-ratio: 4/3">
                  <transition v-if="imageEditor">
                    <imgx :src="editBookImg.slice(4).slice(0, -1)" :imageEditor="true"
                          @editedImageUploadCompleted="editedImageUploadComplete"
                          @editedImageClosed="editedImageClose"></imgx>
                  </transition>
                  <transition v-else>
                    <div v-if="editBookImg != 'url()'"
                      :class="uploadingImage ? 'lighter' : null"
                      :style="{ backgroundImage: editBookImg, height: '100%', width: '100%', backgroundSize: 'cover', borderRadius: '12px', position: 'relative', flex: '1'}" >
                      <span class="blocks_loader" v-if="uploadingImage"></span>
                      <!-- <div class="delete_img_button" @click="editBookImg = 'url()'"></div> -->
                      <div class="delete_img_button" @click="imageWarn = index; imgEdit = false"></div>
                    </div>
                  </transition>
                </div>
              </transition>
              <transition v-else>
                <div v-if="editBookImg != 'url()'" :style="{ backgroundImage: editBookImg, width: '100%', backgroundSize: 'cover', borderRadius: '12px', position: 'relative', flex: '1', aspectRatio: '4/3' }">
                  <div class="edit_img_button" v-if="!imgEdit" @click="imgEdit = !imgEdit"></div>
                  <!-- <div class="delete_img_button" v-if="!imgEdit" @click="editBookImg = 'url()'"></div> -->
                  <!-- <div class="delete_img_button" @click="imageWarn = index; imgEdit = false"></div> -->
                </div>
                <DropZoneImageDiv v-else-if="!imgEdit" @click.prevent="imgEdit = true;"/>
              </transition>
            </div>

            <div v-if="imgEdit && !imageEditor" style="margin-bottom: 25px;">
              <div v-if="editBookImg == 'url()'" class="dropzone_alike" :style="{ color: (uploadingImage ? 'rgba(0, 108, 62, 0.0)' : '') }">
                No Image
                <span class="blocks_loader" v-if="uploadingImage"></span>
              </div>
              <div class="action_button upload_img">
                <!-- <span class="blocks_loader" v-if="!uploadingImage"></span> -->
                <DropZoneImage :index='0' :height="100" @drop.prevent="drop" @change="selectedFile"
                  @uploadCompleted="uploadComplete" @uploadStarted="uploadStarted"/>
              </div>
              <div class="action_button paste_img" @click="pasteImageFromClipboard">Paste from Clipboard</div>
              <div v-if="editBookImg != 'url()'" class="action_button edit_img" @click="tempEditBookImg = editBookImg; imageEditor = true;">Edit Image</div>
            </div>
            <div v-else-if="!imageEditor">
              <div class="tag">
                <p>Title</p>
                <input v-if="this.books[editBook].title.toLowerCase() == 'new book'" type="text" v-model="editBookTitle" :placeholder="this.books[editBook].title">
                <input v-else type="text" v-model="this.books[editBook].title">
              </div>

              <div class="tag">
                <p>Description</p>
                <input v-if="this.books[editBook].desc.toLowerCase() == 'new book description'" type="text" v-model="editBookDesc" :placeholder="this.books[editBook].desc">
                <input v-else type="text" v-model="this.books[editBook].desc">
              </div>

              <div class="tag category_menu" v-if="!addNewCat">
                <p>Category</p>
                <div class="current_category" @click="showAllList = !showAllList"
                  :style="{ backgroundColor: editBookCategory.color !== undefined && editBookCategory.color !== null ? editBookCategory.color : '#bcbcbc' }">
                  {{ editBookCategory.color !== undefined && editBookCategory.name !== null ? editBookCategory.name : 'No Category'}}
                </div>
              </div>

              <transition name="category_list">
                <div class="cat_list_wrapper" v-if="showAllList">
                  <ul class="list_of_categories">
                    <li v-for="cat in categories" :key="cat" @click="changeCategory(cat)" :style="{ backgroundColor: cat.color }">{{ cat.name }}
                      <span @mousedown.prevent="newEditBookCategory = cat; longpressCat()" @mouseup="longpressCatOff()" @touchstart="newEditBookCategory = cat; longpressCat()" @touchend="longpressCatOff()"></span>
                    </li>
                    <li @click="changeCategory(null)" :style="{ backgroundColor: '#bcbcbc' }">No Category</li>
                  </ul>
                  <p>*To edit or delete any of the unselected categories, press and hold.</p>
                </div>
              </transition>

              <div class="add_new_cat" v-if="addNewCat">
                <input placeholder="Operation" v-model="newCatName">
                <span class="choose_color" @click="ifChooseColor = !ifChooseColor">
                  <span class="current_color" :style="{ backgroundColor: newCategory.color }"></span>
                  <span class="rotating_arrow"
                    :style="[ifChooseColor ? { transform: 'rotate(180deg)' } : '']"></span>
                </span>

                <transition name="colors">
                  <div class="palette" v-if="ifChooseColor">
                    <span class="colors" v-for="color in colors" :key="color"
                      @click="newCategory.color = color; ifChooseColor = false">
                      <span :style="{ backgroundColor: color }"></span>
                    </span>
                  </div>
                </transition>
              </div>

              <div class="add_new_cat" v-if="editCategory">
                <input v-model="editBookCategory.name">
                <span class="choose_color" @click="ifChooseColor = !ifChooseColor">
                  <span class="current_color" :style="{ backgroundColor: editBookCategory.color }"></span>
                  <span class="rotating_arrow" :style="[ifChooseColor ? { transform: 'rotate(180deg)' } : '']"></span>
                </span>

                <transition name="colors">
                  <div class="palette" v-if="ifChooseColor">
                    <span class="colors" v-for="color in colors" :key="color"
                      @click="newCategory.color = color; ifChooseColor = false">
                      <span :style="{ backgroundColor: color }"></span>
                    </span>
                  </div>
                </transition>
              </div>

              <div class="new_cat">
                <div v-if="addNewCat || editCategory" @click="addNewCat ? saveCategory(0) : saveCategory(1)" class="button">SAVE</div>
                <div v-if="addNewCat || editCategory" @click="addNewCat = false; editCategory = false; newCatName = ''" class="button secondary">CANCEL</div>
              </div>
            </div>

              <div v-if="!addNewCat && !editCategory && !imgEdit" class="category_actions">
                <div @click="showAllList = false; addNewCat = true; newCatName = ''">add</div>
                <!-- <div @click="showAllList = false; editCategory = true;" -->

                <div v-if="editBookCategory.color == undefined && editBookCategory.color == null" class="disabled">edit</div>
                <div v-else @click="showAllList = false; editCategory = true;">edit</div>

                <div v-if="editBookCategory.color == undefined && editBookCategory.color == null" class="disabled">delete</div>
                <div v-else @click="deleteCategoryCommit = true, deleteCategory = editBookCategory.categoryid">delete</div>
              </div>
            
            <!-- <div v-if="imgEdit && !imageEditor" class="button_updown" @click="imgEdit = false"><span>Done</span></div>
            <div v-if="imgEdit && !imageEditor" class="cancel_updown" @click="imgEdit = false"><span>Done</span></div> -->
            <div v-if="imgEdit && !imageEditor" class="cancel_img_set">
              <div class="cancel_img" @click="imgEdit = false">cancel</div>
              <div class="done_img" @click="universalSave(editedToSave)">done</div>
            </div>

            <div v-if="!imgEdit" class="button_updown" @click="universalSave(editedToSave)"><span>Done</span></div>
          </div>
        </transition>

        <span v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || book.permissions?.includes('delete'))" class="delete_button" @click.stop=" book.context = !book.context; deleteBookIndex = index; deleteBook = true"></span>
        <span v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || book.permissions?.includes('edit'))" @click.stop="editedToSave = 'bookEdit'; editBook = index; editBookImg = book.img; editBookCategory = book.status;" class="edit_book_button"></span>

        <transition name="top-slide">
          <div v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || book.permissions?.includes('edit'))" class="dragger"></div>
        </transition>

        <div :class="[ book.img === 'url()' ? 'section_no_bg' : 'section_bg']" :style="[book.img !== 'url()' ? { backgroundImage: book.img } : {}]">
          <span class="status" v-if="book.status.name !== null" :style="[book.status.color == null ? {backgroundColor: '#bcbcbc'} : {backgroundColor: book.status.color}]">{{book.status.name}}</span>
          <span @click.stop="collapseBook($event)" class="collapse_button"></span>
          <p v-if="book.img === 'url()'">{{ book.title }}</p>
          <ul class="media_data" v-if="book.doc != undefined && book.list != undefined && book.video != undefined">
            <li class="doc" v-if="book.doc">
              <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29675 5.30749V19.0086H17.1631V10.032H13.6198C12.9674 10.032 12.4386 9.5032 12.4386 8.85088V5.30749H6.29675ZM13.856 6.30971L16.1609 8.61465H13.856V6.30971ZM4.87939 5.07127C4.87939 4.41895 5.4082 3.89014 6.06052 3.89014H13.1473C13.3353 3.89014 13.5155 3.9648 13.6484 4.0977L18.3729 8.82222C18.5058 8.95512 18.5805 9.13538 18.5805 9.32333V19.2448C18.5805 19.8971 18.0517 20.4259 17.3994 20.4259H6.06052C5.40821 20.4259 4.87939 19.8971 4.87939 19.2448V5.07127Z"/>
              </svg>
            </li>
            <li class="video" v-if="book.video">
              <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.518 3.89014C4.12661 3.89014 3.80933 4.20742 3.80933 4.59881V19.7173C3.80933 20.1087 4.12661 20.4259 4.518 20.4259H19.6365C20.0279 20.4259 20.3451 20.1087 20.3451 19.7173V4.59881C20.3451 4.20742 20.0279 3.89014 19.6365 3.89014H4.518ZM5.22668 19.0086V5.30749H18.9278V19.0086H5.22668ZM9.71497 9.26121V15.0549C9.71497 15.4322 10.1355 15.6573 10.4495 15.448L14.7947 12.5511C15.0752 12.3641 15.0752 11.9519 14.7947 11.7649L10.4495 8.86811C10.1355 8.6588 9.71497 8.88387 9.71497 9.26121Z"/>
              </svg>

            </li>
            <li class="list" v-if="book.list">
              <svg width="23" height="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.28235 7.906C6.06513 7.906 6.6997 7.27143 6.6997 6.48864C6.6997 5.70586 6.06513 5.07129 5.28235 5.07129C4.49956 5.07129 3.86499 5.70586 3.86499 6.48864C3.86499 7.27143 4.49956 7.906 5.28235 7.906ZM9.53441 5.77997C9.14302 5.77997 8.82573 6.09725 8.82573 6.48864C8.82573 6.88004 9.14302 7.19732 9.53441 7.19732L18.9834 7.19732C19.3748 7.19732 19.6921 6.88004 19.6921 6.48865C19.6921 6.09725 19.3748 5.77997 18.9834 5.77997L9.53441 5.77997ZM9.53441 11.4494C9.14302 11.4494 8.82573 11.7667 8.82573 12.1581C8.82573 12.5495 9.14302 12.8667 9.53441 12.8667L18.9834 12.8667C19.3748 12.8667 19.6921 12.5495 19.6921 12.1581C19.6921 11.7667 19.3748 11.4494 18.9834 11.4494L9.53441 11.4494ZM8.82573 17.8275C8.82573 17.4361 9.14302 17.1188 9.53441 17.1188L18.9834 17.1188C19.3748 17.1188 19.6921 17.4361 19.6921 17.8275C19.6921 18.2189 19.3748 18.5362 18.9834 18.5362L9.53441 18.5362C9.14302 18.5362 8.82573 18.2189 8.82573 17.8275ZM6.6997 12.1581C6.6997 12.9409 6.06513 13.5754 5.28235 13.5754C4.49956 13.5754 3.86499 12.9409 3.86499 12.1581C3.86499 11.3753 4.49956 10.7407 5.28235 10.7407C6.06513 10.7407 6.6997 11.3753 6.6997 12.1581ZM5.28235 19.2448C6.06513 19.2448 6.6997 18.6103 6.6997 17.8275C6.6997 17.0447 6.06513 16.4101 5.28235 16.4101C4.49956 16.4101 3.86499 17.0447 3.86499 17.8275C3.86499 18.6103 4.49956 19.2448 5.28235 19.2448Z"/>
              </svg>
            </li>
          </ul>
        </div>
            
        <h3 id="sectiontitle tileTitle">{{ book.title }}</h3>
        <p id="sectiondesc tileSubtext" v-text="book.desc.toLowerCase() == 'new book description' ? '' : book.desc"></p>

        <div class="assign universal_modal" @click.stop v-if="assign">
          <h2>Assign book to one or more sections</h2>
          <div class="assign_gray">
            <div class="search">
              <input type="text" v-model="search" placeholder="Search"/>
            </div>
            <p class="amount">{{ `${selectedAmount} sections assigned` }}</p>

            <div class="sections">
              <div v-for="(line, index) in filteredList" :key="index" class="line">
                <label>
                  <input type="checkbox" :name="'sectionId' + line.sectionid" @click="changeSection(line.sectionid);" :checked="this.bookSectionsAssignList.sectionsIds.includes(line.sectionid.toString())">
                  {{ line.Name }}
                  <i class="checkbox"></i>
                </label>
              </div>
            </div>

          </div>
          <div class="buttons">
            <btn class="button secondary" v-text="'cancel'" @click.stop="assign = !assign"></btn>
            <btn class="button" v-text="'save'" @click.stop="assign = !assign; saveBookLinks(book.id);"></btn>
          </div>
        </div>

        <div v-if="edit && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('add'))" class="add_book" @click.stop="addBook(index)">Add Book</div>

        <div v-if="edit && index == books.length - 1 && !lastAddBookButton && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || book.permissions?.includes('add'))" class="add_book_bottom" @click.stop="addBook(books.length)">Add Book</div>
      </div>
    </transition-group>
    
    <div v-if="noBooks && beforeLoad && ((this.$store.state.user.role.includes('admin') || this.$store.state.user.role.includes('bOpus_admin')) || this.permissions?.includes('add'))" class="add_book_alone" :style="[edit ? { 'border-color': '#099f45', color: '#099f45', cursor: 'pointer' } : { 'border-color': '#787878', color: '#787878' }]"
      @click.stop="edit ? addBook(0) : null">
      <div :style="[edit ? { 'border-color': '#099f45' } : { 'border-color': '#787878' }]">
        <span>Add Book</span>
        <p class="plus">+</p>
      </div>
    </div>
    <p v-if="edit && books.length == 0 && beforeLoad" class="no_content">
      You don’t have any books<br/><br/>
      Please click the Add Book button to get started.
    </p>
    <p v-if="!edit && books.length == 0 && beforeLoad" class="no_content">
      You don’t have any books<br/><br/>
      To get started, make sure you have editor privileges.  Once you do, click on the menu icon in the upper left hand corner.<br/>
      <i></i>
      Once you’ve clicked that icon, you will see a button just under your name that says “Edit Mode”. Click it and you’ll have the ability to add books.
    </p>
    <div v-if="edit && lastAddBookButton && books.length != 0" class="add_book_bottom" :class="{ wierd_class: lastAddBookButton }" @click.stop="addBook(books.length)">Add Book</div>
  </draggable>
    

    <div class="context_shadow" v-if="titleEdit || editBook >= 0 || bookCreate || deleteCurrent || assign || deleteBook || imgEdit || imageWarn >= 0 || deleteCategoryCommit" @click.stop="universalSave(editedToSave)"></div>
    <!-- <div class="context_shadow_two" v-if="imgEdit" @click="imgEdit = false"></div>  -->

    <transition name="totop_modal">
      <div v-if="titleEdit" class="updown section_info" :style="[width < 600 ? { paddingBottom: '60px' } : {}]">
        <span class="options_toggle" @click="universalSave('sectionEdit')">SECTION EDIT</span>
        <div class="tag">
          <p>Title</p>
          <input type="text" v-model="this.title">
        </div>
        
        <div class="tag">
          <p>Icon</p>
          <div class="selected_icon" :style="{ backgroundImage: icon }"></div>
          <div @click="iconsToChange = !iconsToChange" class="change_icon_button" v-text="iconsToChange ? 'Cancel' : 'Change'"></div>
        </div>

        <transition name="totop">
          <div v-if="iconsToChange">
            <div class="tag icons_type">
              <p>Category</p>
              <div>
                <ul :style="iconTypesOpen ? {boxShadow : '0px 2px 4px 0px #00000040'} : null">
                  <transition v-for="(type, index) in iconTypes" :key="index">
                    <li v-if="this.iconTypesOpen || type === currentIconType" @click="this.iconTypesOpen = !this.iconTypesOpen; chooseCategory(type,index)"> {{ type.replaceAll('_', ' ') }}
                    </li>
                  </transition>
                </ul>
                <span @click="this.iconTypesOpen = !this.iconTypesOpen;" :style="iconTypesOpen ? { transform: 'rotate(180deg)' } : null"></span>
              </div>
            </div>
            <div class="" v-for="(type, index) in iconTypes" :key="index">
              <div v-if="currentIconType === type" class="box_of_icons">
                <span class="icon_in_category" v-for="(icon, index) in iconList[type]" :key="index"
                  :style="[{ backgroundImage: 'url(../' + icon.fullPathUrl + ')' }]"
                  @click="selectIcon('url(../' + icon.fullPathUrl + ')')">
                </span>
              </div>
            </div>
          </div>
        </transition>
        <!-- <div class="delete_updown" @click="this.sectionDelete = true"><span></span>DELETE THIS SECTION</div> -->
        <div class="button_updown" @click="universalSave('sectionEdit')"><span>Done</span></div>
      </div>
    </transition>

    
    <div class="delete_edit_cat" v-if="deleteCategory >= 0" :style="[deleteCategoryCommit ? {zIndex: '1'} : null]">
      <h3>Edit / Delete Category</h3>
      <div class="delete_top">
        Delete Category
        <div @click="catToDelete = newEditBookCategory.categoryid; deleteCategoryCommit = true">Delete</div>
      </div>
      <div>
        <p>Edit Category</p>
        <div class="add_new_cat">
          <input v-model="newEditBookCategory.name">
          <span class="choose_color" @click="ifChooseColor = !ifChooseColor">
            <span class="current_color" :style="{ backgroundColor: newEditBookCategory.color }"></span>
            <span class="rotating_arrow"
              :style="[ifChooseColor ? { transform: 'rotate(180deg)' } : '']"></span>
          </span>

          <transition name="colors">
            <div class="palette" v-if="ifChooseColor">
              <span class="colors" v-for="color in colors" :key="color"
                @click="newEditBookCategory.color = color; ifChooseColor = false">
                <span :style="{ backgroundColor: color }"></span>
              </span>
            </div>
          </transition>
        </div>
      </div>
      <div class="buttons">
        <btn class="secondary" v-text="'cancel'" @click="deleteCategory = -1; count = 0; interval = null;"></btn>
        <btn v-text="'save'" @click="saveCategory(4)"></btn>
      </div>
    </div>

    <div class="delete_anything" @click.stop v-if="deleteBook || deleteCurrent || imageWarn >= 0 || deleteCategoryCommit">
      <h3 v-if="deleteCurrent">Section Deletion Warning!</h3>
      <h3 v-if="deleteBook">Book Deletion Warning!</h3>
      <h3 v-if="imageWarn >= 0">Image Deletion Warning!</h3>
      <h3 v-if="deleteCategoryCommit || deleteCategory >= 0">Category Deletion Warning!</h3>
      <div class="trash_can"></div>
      <div v-if="deleteCurrent" class="warn functional">
        If you delete this section, all books and pages that are contained within the library will be DELETED. 
        <br/><br/>
        If you are sure, select the green button below.
      </div>
      <div v-if="deleteBook" class="warn functional">
        If you delete this book, all pages, any text, images or other content included in the book will be DELETED. 
        <br/><br/>
        If you are sure, select the green button below.
      </div>
      <div v-if="imageWarn >= 0" class="warn functional">
        This image WILL BE DELETED!
        <br/><br/>
        If you are sure you want to delete, select the green button below.
      </div>
      <div v-if="deleteCategoryCommit || deleteCategory >= 0" class="warn functional">
        You are deleting an book Category.  If this category is in use by another user, you will be removing this Category from their books as well.
        <br/><br/>
        If you are sure you want to delete, select the green button below.
      </div>
      <div class="buttons">
        <btn class="button" v-if="deleteBook" v-text="'delete book'" @click="deleteThisBook"></btn>
        <btn class="button" v-text="'delete section'" v-if="deleteCurrent" @click="deleteSection"></btn>
        <btn class="button" v-if="imageWarn >= 0" v-text="'delete image'" @click="editBookImg = 'url()'; tempEditBookImg = 'url()'; imageWarn = -1"></btn>
        <btn class="button" v-if="deleteCategoryCommit" v-text="'delete category'" @click="saveCategory(3)"></btn>
        
        <btn class="button secondary" v-text="'cancel'" @click="deleteBook = false; deleteCurrent = false; imageWarn = -1; deleteCategoryCommit = false; count = 0; interval = null; deleteCategory = -1"></btn>
      </div>
    </div>

  </main>
</template>

<script>
import { defineAsyncComponent} from 'vue';
import btn from '../components/buttons/button.vue';
import breadcrumb from '../components/breadcrumbtree/breadcrumb.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import imgx from '../components/imageeditor/image.vue';
import LoadingOverlay from '../components/preloaders/LoadingOverlay.vue';
import whiteOverlay from '../components/preloaders/whiteOverlay.vue';

const DropZoneImage = defineAsyncComponent(
  () => import('../components/dropzones/cloudflare_image_section.vue'),
);
const DropZoneImageDiv = defineAsyncComponent(
  () => import('../components/dropzones/cloudflare_image_section_div.vue'),
);

export default {
  name: 'Section',
  components: {
    btn,
    DropZoneImage,
    DropZoneImageDiv,
    draggable: VueDraggableNext,
    breadcrumb,
    imgx,
    LoadingOverlay,
    whiteOverlay
  },
  inject: ['apiPath', 'imagesControl'],
  data() {
    return {
      draft: false,
      uploadingImage: false,
      imageWarn: -1,
      imgEdit: false,
      imageEditor: false,
      editBook: -1,
      currentUrl: '',
      uploading: false,
      img: '',
      icon: '',
      deleteBook: false,
      deleteBookIndex: 0,
      bookToEdit: '',
      assign: false,
      deleteCurrent: false,
      deleteCategory: -1,
      search: '',
      permissions: [],
      shared: 1,
      books: [],
      tempSections: [],
      sectionsDuplicate: [],
      reassignToSections: [],
      categories: [],
      sectionList: [],
      bookSectionsAssignList: { 'bookId': null, 'sectionsIds': [] },
      iconsToChange: false,
      selectedIcon: '',
      currentIconType: 'Default',
      iconTypes: ['Default'],
      iconTypesOpen: false,
      iconList: {
        Default: [
          {fullPathUrl: 'img/bopus/bopus_hat_only_grey.svg'},
          {fullPathUrl: 'img/library/arrange.svg'},
          {fullPathUrl: 'img/library/maintanance.svg'},
          {fullPathUrl: 'img/library/fix.svg'},
          {fullPathUrl: 'img/library/book.svg'},
          {fullPathUrl: 'img/library/navigation.svg'},
          {fullPathUrl: 'img/library/sos_black.svg'},
        ]
      },
      titleEdit: false,
      showAllList: false,
      editBookTitle: '',
      editBookImg: '',
      tempEditBookImg: '',
      editBookDesc: '',
      categoryToDisplay: {},
      editBookCategory: '',
      newEditBookCategory: '',
      newCatName: '',
      colors: ['#252525', '#787878', '#bcbcbc', '#2884C7', '#099F45', '#F44336', '#FF6B00', '#FFA800'],
      newCategory: {name: '', color: 'rgb(9, 159, 69)', customerid: '1', deleted: '0' },
      ifChooseColor: false,
      addNewCat: false,
      editCategory: false,
      allCollapsed: false,
      editedToSave: '',
      width: window.innerWidth,
      height: window.innerHeight,
      beforeLoad: false,
      deleteCategoryCommit: false,
      count: 0,
      interval: null,
      catToDelete: '',
    };
  },
  computed: {
    edit() {
      return this.$store.state.edit;
    },
    paddingLeft() {
      if (this.width <= 1920 && this.width >= 481) {
        return '46px';
      } else if (this.width <= 480){
        return '30px';
      } else {
        return '80px';
      }
    },
    paddingRight() {
      if (this.width <= 1920 && this.width >= 481) {
        return '46px';
      } else if (this.width <= 480){
        return '30px';
      } else {
        return '80px';
      }
    },
    lastAddBookButton() {
      if (this.width <= 1024 && this.width >= 600 && this.width < this.height && this.books.length % 2 == 0) {
        return true;
      } else if(this.height <= 1024 && this.height >= 600 && this.height < this.width && this.books.length % 3 == 0) {
        return true;
      } else {
        return false;
      }
    },
    noBooks() {
      if (this.books.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    sectionsAmount() {
      const keys = Object.keys(this.books);
      return keys.length;
    },
    filteredCategories() {
      const newMap = new Map();
      this.categories.forEach((item) => newMap.set(item.name, item));
      return newMap;
    },
    selectedAmount() {
      let amount = 0;
      for (let i = 0; i < this.reassignToSections.length; i += 1) {
        if (this.reassignToSections[i].selected) {
          amount += 1;
        }
      }
      return amount;
    },
  },
  methods: {
    here(i){
      console.log(this.editBookImg, 'editBookImg.slice(4).slice(0, -1)')
    },
    longpressCat() {
      this.increment()
      this.interval = setInterval(this.increment, 100);
      if (this.increment >= 10){
        clearInterval(this.interval)
      }
    },
    longpressCatOff() {
      clearInterval(this.interval)
      this.count = 0
    },
    increment() {
      if (this.interval != null && this.count <= 10) {
        this.count = this.count + 1;
      } else if (this.count >= 10){
        this.deleteCategory = this.catToDelete
        clearInterval(this.interval)
      }
    },
    changeCategory(cat) {
      console.log(cat)
      if (this.count < 10) {
        if (cat != null){
          let newCat = { categoryid: cat.categoryid, color:cat.color, name: cat.name }
          this.books[this.editBook].status = newCat;
          this.editBookCategory = newCat;
        } else {
          let newCat = { categoryid: null, color: null, name: null }
          this.books[this.editBook].status = newCat;
          this.editBookCategory = newCat;
        }
        this.showAllList = false;
        this.count = 0;
        clearInterval(this.interval)
      }
    },
    chooseCategory(type,i) {
      this.iconTypes.unshift(this.iconTypes.splice(i, 1)[0]);
      this.currentIconType = type;
      console.log(this.currentIconType,'this.type')
    },
    async sendFetch(url, data = null) {
      let formData = new FormData();
      formData.append('token', this.$store.state.usertoken);
      if (data !== null) {
        Object.keys(data).forEach(function(key) {
          formData.append(key, data[key]);
        });
      }
      return await fetch(`${this.apiPath}` + url, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then((response) => {
        if (response.result) {
          return response.result;
        } else {
          console.log('Looks like there was a problem: \n', response.error);
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          return false;
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
        return false;
      });
    },
    drop() {

    },
    selectedFile() {

    },
    universalSave(part = null) {
      if (part !== null) {
        switch (part) {
          case 'sectionEdit':
            console.log('TEST', this.$route.params.id, this.title, this.icon);
            this.sendFetch(
                'entity/section/edit',
                {
                  "sectionid": this.$route.params.id,
                  "Name": this.title,
                  "Icon": this.icon
                }
            )
            break;
          case 'bookEdit':
            this.books[this.editBook].img = this.editBookImg;
            if (this.editBookTitle != '') {
              this.books[this.editBook].title = this.editBookTitle;
            } else if (this.editBookTitle == '' && this.books[this.editBook].title.toLowerCase() != 'new book') {
              this.editBookTitle = this.editBookTitle;
            } else {
              this.books[this.editBook].title = 'New Book'
            }
            if (this.editBookDesc != '') {
              this.books[this.editBook].desc = this.editBookDesc;
            } else if (this.editBookDesc == '' && this.books[this.editBook].desc != 'new book description') {
              this.editBookDesc = this.editBookDesc;
            } else {
              this.books[this.editBook].desc = 'New Book Description'
            }
            let data = {
              "articleid": this.books[this.editBook].id,
              "categoryid": this.editBookCategory.categoryid !== undefined ? this.editBookCategory.categoryid : null,
              "name": this.books[this.editBook].title,
              "thumbnail": this.imagesControl.getLinkForDB(this.books[this.editBook].img),
              "subTitle": this.books[this.editBook].desc,
            };
            // if (this.books[this.editBook].status.id !== undefined && this.books[this.editBook].status.id !== null) {
            //   data.categoryid = this.books[this.editBook].status.id;
            // }
            console.log(data,'data console log')
            this.sendFetch(
                'entity/article/edit',
                data
            );
            break;
          default:
          console.log('wrong action');
        }
      }
      this.editBookTitle = '';
      this.editBookDesc = '';
      this.editBook = -1;
      this.titleEdit = false;
      this.assign = false;
      this.bookToEdit = '';
      this.deleteBook = false;
      this.showAllList = false;
      this.addNewCat = false;
      this.imgEdit = false;
      this.deleteCurrent = false;
      this.deleteCategory = -1;
      this.imageEditor = false;
      this.editBookImg = 'url()';
      this.tempEditBookImg = 'url()';
      this.imageWarn = -1;
      this.count = 0;
      this.deleteCategoryCommit = false;
      clearInterval(this.interval);
    },
    deleteSection() {
      this.sendFetch(
        'entity/section/delete',
        {
          "sectionid": this.$route.params.id,
        }
      ).then(() => {
        this.$router.go(-1)
      })
    },
    selectIcon(im) {
      this.icon = im;
      this.iconsToChange = false;
    },
    async saveSortOrder() {
      let booksIds = [];
      this.books.forEach(function (e) {
        booksIds.push(e.id);
      });
      if (booksIds.length > 0) {
        return this.sendFetch(
            'entity/article/resort',
            {
              "ids": JSON.stringify(booksIds)
            }
        )
      }
    },
    saveCategory(n) {
      if (n == 0 && this.newCatName) {
        this.newCategory.name = this.newCatName
        this.sendFetch(
            'entity/category/add',
            {
              "name": this.newCategory.name,
              "color": this.newCategory.color,
            }
        ).then((response) => {
          if (response) {
            this.newCategory.categoryid = response.toString();
            this.categories.push(this.newCategory);
            this.books[this.editBook].status = this.newCategory;
            this.editBookCategory = this.newCategory;
            this.addNewCat = false;
            this.newCategory = { name: '', color: 'rgb(9, 159, 69)', project: '1', deleted: '0' };
          }
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      } else if (n == 1) {
        this.sendFetch(
            'entity/category/edit',
            {
              "categoryid": this.editBookCategory.categoryid,
              "name": this.editBookCategory.name,
              "color": this.editBookCategory.color,
            }
        ).then((response) => {
          this.editCategory = false;
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      } else if (n == 2 && this.editBookCategory.categoryid != null) {
        console.log('111111111')
        this.sendFetch(
            'entity/category/delete',
            {
              "categoryid": this.deleteCategory,
            }
        ).then((response) => {
          // this.books[this.editBook].status = {}
          this.editBookCategory = {};
          this.sendFetch(
              'entity/category/find',
              {
              }
          ).then((result)=>{
            this.categories = result;
            this.books[this.editBook].status = {}
            this.deleteCategory = -1
          });
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      } else if (n == 3 && this.newEditBookCategory.categoryid !== null) {
        console.log(this.deleteCategory, '222222222')
        let deleteID;
        if (this.newEditBookCategory == ''){
          deleteID = this.books[this.editBook].status.id
        } else {
          deleteID = this.newEditBookCategory.categoryid
        }
        this.sendFetch(
            'entity/category/delete',
            {
              "categoryid": deleteID,
            }
        ).then((response) => {
          console.log(response, 'response')
          // if ()
          // this.books[this.editBook].status = {}
          // this.editBookCategory = {};
          this.newEditBookCategory = {};
          this.sendFetch(
              'entity/category/find',
              {
              }
          ).then((result)=>{
            this.categories = result;
            // this.books[this.editBook].status = {}
            this.deleteCategory = -1
            this.deleteCategoryCommit = false;
            this.count = 0;
          });
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
        console.log(this.editBookCategory, 'this.editBookCategory')
      } else if (n == 4) {
        this.sendFetch(
            'entity/category/edit',
            {
              "categoryid": this.newEditBookCategory.categoryid,
              "name": this.newEditBookCategory.name,
              "color": this.newEditBookCategory.color,
            }
        ).then((response) => {
          this.editCategory = false;
          this.deleteCategory = -1;
          this.newEditBookCategory = {};
          this.count = 0;
        }).catch((error) => {
          console.log('Looks like there was a problem: \n', error);
        });
      }
    },
    collapseBook(elem) {
      const el = elem.target.closest('.menu_item');
      if (el.classList.contains('collapsed')) {
        el.classList.remove('collapsed')
      } else { 
        el.classList.add('collapsed')
      }
    },
    addBook(it) {
      this.books.splice(it, 0, { title: 'New Book', img: 'url()', desc: 'New Book Description', status: {id: null, color: null, name: null}, 'permissions': this.permissions, 'shared': this.shared });
      this.sendFetch(
          'entity/article/add',
          {
            "sectionid": this.$route.params.id,
            // "categoryid": "",
            "name": this.books[it].title,
            "thumbnail": this.books[it].img,
            "subTitle": this.books[it].desc,
            "shared": this.books[it].shared
          }
      ).then(async (response) => {
        if (response) {
          this.books[it].id = response.toString();
          this.books[it].route = '/article/' + response.toString();
          await this.saveSortOrder();
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    },
    deleteImage() {
      this.img = "";
    },
    uploadStarted(path) {
      this.uploading = true;
      // console.log("Image Path" + path);
      let url = '';
      url = path;
      url = url.replace('upload.','');
      url = url + "/section";
      this.currentUrl = url;
    },
    uploadComplete(index) {
      if (index >= 0) {
        //this.books[this.editBook].img = `${'url('+this.currentUrl+')' }`;
        this.tempEditBookImg = this.editBookImg;
        this.editBookImg = `${'url(' + this.currentUrl + ')'}`;
        console.log(this.editBookImg,'this.editBookImg')
        this.imageEditor = true;
      }
      if(index == -1) {
        console.log("Upload Error");
      }
      if(index == -2) {
        console.log("Upload Aborted");
      }
      this.uploading = false;
      this.uploadEmpty = false;
    },
    editedImageUploadComplete(newUrl) {
      if (newUrl !== undefined && newUrl !== null && newUrl !== '') {
        this.editBookImg = 'url(' + newUrl + ')';
        console.log(this.editBookImg, 'editBookImg');
        this.imageEditor = false;
      } else {
        console.log('Image is not uploaded');
      }
    },
    editedImageClose() {
      this.editBookImg = this.tempEditBookImg;
      this.imageEditor = false;
    },
    saveBookLinks(){
      let formData = new FormData();
      formData.append('articleId', this.bookSectionsAssignList.bookId);
      formData.append('sectionsIds', this.bookSectionsAssignList.sectionsIds);
      formData.append('token', this.$store.state.usertoken);
      fetch(`${this.apiPath}changeArticleLinks`, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then(() => {
        this.getData();
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
      //this.bookSectionsAssignList = {'bookId': null, 'sectionsIds': []};
    },
    changeSection(sectionId){
      let index = this.bookSectionsAssignList.sectionsIds.indexOf(sectionId.toString());
      if (index !== -1) {
        this.bookSectionsAssignList.sectionsIds.splice(index, 1);
      } else {
        this.bookSectionsAssignList.sectionsIds.push(sectionId.toString());
      }
    },
    deleteThisBook() {
      let id = this.books[this.deleteBookIndex].id.toString();
      if (Array.isArray(this.books)) {
        this.books.splice(this.deleteBookIndex, 1);
      } else {
        delete this.books[this.deleteBookIndex];
      }
      this.sendFetch(
          'entity/article/delete',
          {
            "articleid": id
          }
      );
      this.deleteBook = false;
    },
    async pasteImageFromClipboard() {
      let imageObj = await this.imagesControl.getImageFromClipboard();
      if (imageObj) {
        this.uploadingImage = true;
        this.uploadImageToCloudflare(imageObj.file).then((result) => {
          if (result) {
            //this.imagesControl.set(result, `url(${imageObj.url})`);
            this.tempEditBookImg = this.editBookImg;
            this.editBookImg = `url(${imageObj.url})`;
            this.imageEditor = true;
            this.uploadingImage = false;
          }
        });
      }
    },
    async uploadImageToCloudflare(file) {
      const formData = new FormData();
      formData.append('file', file);
      return new Promise((resolve, reject) => {
        this.getOneTimeUploadUrl().then((url) => {
          const request = new XMLHttpRequest();
          let link = url.replace('https://upload.', 'https://') + '/public';
          request.open('POST', url);
          request.onerror = () =>
              reject('oh no something went wrong!');
          request.ontimeout = () =>
              reject('oh no request timed out!');
          request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
              resolve(link);
            } else {
              reject('oh no something went wrong!');
            }
          };
          request.send(formData);
        });
      });
    },
    async getOneTimeUploadUrl() {
      let response = await fetch(`${this.apiPath}cloudflareGetUploadLinkImage?token=${this.$store.state.usertoken}`);
      return await response.text();
    },
    getData() {
      fetch(`${this.apiPath}sectionData?sectionid=${this.$route.params.id}&v2=1&token=${this.$store.state.usertoken}`, {
        headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
        if (response.error === undefined) {
          this.beforeLoad = true;
          this.books = response.articles;
          for (let i = 0; i < this.books.length; i++) {
            let tempImage = this.imagesControl.get(this.books[i].img.slice(4).slice(0, -1));
            this.books[i].img = tempImage !== null ? tempImage : this.books[i].img;
          }
          this.tempSections = JSON.parse(JSON.stringify(this.books));
          // this.sectionsDuplicate = {...this.sections}
          this.sectionsDuplicate = {...response.articles};
          this.categories = response.categories;
          this.sectionList = response.sections;
          this.permissions = response.permissions;
          this.shared = response.shared;
          this.icon = response.icon;
          this.reassignToSections = Object.entries(response.sections);
          this.title = response.name;
          this.img = '';
          //let resp = [response.name, `${'/section/' + Object.keys(response.sections)}`, '1']; //todo why?
          let resp = [response.name, `${'/section/' + this.$route.params.id.toString()}`, '1'];
          this.$store.state.iconsList.then((icons)=> {
            let sectionsIcons = icons.img.folders.library.folders;
            for (const [key, value] of Object.entries(sectionsIcons)) {
              this.iconTypes.push(key);
              this.iconList[key] = value.files;
            }
            console.log(sectionsIcons);
          });
        } else {
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          if (response.error === '403') {
            window.location.href = "/403";
          }
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    },
  },
  beforeMount() {
    if (window.innerWidth < 600 && window.innerHeight > window.innerWidth) {
      this.phone = true;
    } else {
      this.phone = false;
    }
  },
  mounted() {
    this.emitter.all.clear();
    this.getData();

    this.emitter.on('collapse_toggle', (evt) => {
      let toCollatse = document.getElementsByClassName("menu_item"),
        len = toCollatse !== null ? toCollatse.length : 0,
        i = 0;
      for (i; i < len; i++) {
        toCollatse[i].className += "collapsed";
      }
    })
  },
};
</script>

<style lang="scss" scoped>
main{
  .page_header {
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   margin-top: 95px;
    // }
  }
  .page_header.draft{
    color: #006C3E;
    .books_draft{
      width: 100%;
      background-color: #006C3E;
      border-radius: 12px;
      position: relative;
      color: #fff;
      padding: 16px;
      box-sizing: border-box;
      font-weight: 400;
      position: relative;
      padding-right: 90px;
      margin-top: 12px;
      margin-bottom: 16px;
      p{
        font-size: .82rem;
        margin-bottom: 0px;
      }
      h2{
        margin: 0px;
        font-weight: 400;
      }
      svg{
        position: absolute;
        height: 70px;
        width: 70px;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(../../public/img/home/create.svg);
      }
    }
    .books_draft_edit{
      margin-left: -46px;
      margin-right: -46px;
      width: calc(100% + 92px);
      @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
        margin-left: -30px;
        margin-right: -30px;
        width: calc(100% + 60px);
      }
    }
  }
}
.section_info{
  .options_toggle::before,.options_toggle::after{
    width: 120px;
    @media screen and (max-width: 599px) and (orientation: portrait),
    screen and (max-height: 500px) and (orientation: landscape){
      width: calc(50% - 95px);
    }
  }
}
.icons_type, .icon_in_category {
  cursor: pointer;
}
.book_info{
  .options_toggle::before,.options_toggle::after{
    width: 120px;
    @media screen and (max-width: 599px) and (orientation: portrait),
    screen and (max-height: 500px) and (orientation: landscape){
      width: calc(50% - 95px);
    }
  }
  .tag{
    .lighter{
      position: relative;
    }
    .lighter::after{
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: rgba(256, 256, 256, 0.7);
      border-radius: 12px;
    }
    >p{
      width: 120px;
    }
  }
}
.add_book,.add_book_bottom{
  color: #787878;
  font-size: 1rem;
  padding-left: 35px;
  background-image: url(../../public/img/section/add_line.svg);
  background-repeat: no-repeat;
  background-position: center left;
  position: absolute;
  left: -88px;
  top: 50%;
  line-height: 44px;
  height: 44px;
  transform: rotate(-90deg) translateX(5%);
}
.add_book_bottom{
  left: auto;
  right: -88px;
}
.emptyimage {
  background-color:#ebf0ee;
  border-radius: 12px;
  text-align: center;
  width: 100%;
  height: inherit;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(0, 108, 62, 0.15)' stroke-width='4' stroke-dasharray='6%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  p{
    position: absolute;
    color: #787878;
    font-weight: 400;
    font-size: 0.76rem;
    bottom: 20px;
    left: 0px;
    right: 0px;
    text-align: center;
  }
}
.upload_section{
  .secondary{
    margin-top: 32px;
  }
  h2{
    text-align: left;
  }
  label{
    text-align: left;
  }
  text-align: center;
}
.delete_book{
  .trash_can{
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../public/img/trash.png);
  }
}
.delete_book{
  position: fixed;
  background-color: #fff;
  width: 770px;
  box-sizing: border-box;
  border-radius: 16px;
  top: 10%;
  z-index: 3;
  left: 50%;
  margin-left: -385px;
  p{
    max-width: 495px;
    margin: 0 auto;
    font-size: 16px;
    color: #787878;
    text-align: left;
  }
}
.add_book_button{
  position: fixed;
  bottom: 20px;
  left: 50%;
  width: 320px;
  max-width: 90%;
  padding: 0 24px 0 35px;
  margin-left: -160px;
}
.assign{
  position: fixed;
  background-color: #fff;
  width: 770px;
  box-sizing: border-box;
  border-radius: 16px;
  top: 10%;
  z-index: 3;
  left: 50%;
  margin-left: -385px;
  cursor: default;
  .assign_gray{
    margin-top: 32px;
    background-color: rgba(120, 120, 120, 0.05);
    padding: 16px 0 16px 16px;
    border-radius: 8px;
    box-sizing: border-box;
    height: 295px;
    overflow: hidden;
    .search{
      height: 40px;
      input{
        height: 40px;
        width: 657px;
        text-indent: 45px;
        box-sizing: border-box;
        border: none;
        outline: none;
        border-radius: 8px;
        background-image: url(../../public/img/search.svg);
        background-repeat: no-repeat;
        background-position: 10px center;
        &:active{
          border: 0;
          outline: none;
          background-image: none;
          text-indent: 8px;
        }
        &:focus{
          border: 0;
          outline: none;
          background-image: none;
          text-indent: 8px;
        }
      }
      input::placeholder{
        text-indent: 35px;
        font-size: 14px;
        color: #787878;
        padding-left: 15px;
        text-align: left;
      }
      input:focus::placeholder,input:active::placeholder{
        color: transparent;
      }
    }
    .sections{
      width: 660px;
      overflow-y: scroll;
    }
    .amount{
      color: #252525;
      font-size: 12px;
    }
    .line{
      display: flex;
      position: relative;
      margin-top: 8px;
      height: 40px;
      input[type="checkbox"]{
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      label{
        height: 40px;
        display: inline-flex !important;
        align-items: center;
        user-select: none;
        color: #252525;
        font-size: 12px;
        background-color: #fff;
        width: 100%;
        border-radius: 8px;
        margin-left: 26px;
        padding-left: 16px;
      }
      .checkbox{
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #787878;
        border-radius: 4px;
        margin-right: 9px;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0px;
        top: 50%;
        margin-top: -8px;
      }
      input[type="checkbox"]:checked+.checkbox {
        background-image: url(../../public/img/check.svg);
        background-color: #787878;
      }
    }
  }
}
  .books{
    h2{
      margin-bottom: 8px;
    }
    .ghost{
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
    }
  }
  .context_shadow {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .context_shadow_two {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .add_book_alone{
    font-size: 1rem;
    color: #787878;
    aspect-ratio: 1/1;
    max-width: 330px;
    max-height: 330px;
    border-radius: 16px;
    box-sizing: border-box;
    cursor: default;
    border: 4px dashed;
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
    padding: 16px 16px 120px 16px;
    div{
      border: 4px dashed;
      border-radius: 16px;
      height: 100%;
      width: 100%;
      position: relative;
      span{
        display: inline-block;
        position: absolute;
        bottom: -40px;
      }
      .plus{
        margin: 0px;
        position: absolute;
        font-size: 50px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .menu_item{
    display: inline-block;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px 1px rgba(37, 37, 37, 0.04);
    border-radius: 16px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 16px;
    margin-right: 16px;
    text-decoration: none;
    flex: 0 0 24%;
    height: 100%;
    transition: all 0.3s ease-out;
    min-width: 265px;
    .delete_button,.edit_book_button,.collapse_button{
      position: absolute;
      display: inline-block;
      height: 46px;
      padding: 0 16px;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 8px;
      line-height: 40px;
      color: #787878;
      font-size: 14px;
      cursor: pointer;
    }
    .delete_button{
      left: 10px;
      background-image: url(../../public/img/delete.svg);
    }
    .edit_book_button{
      background-image: url(../../public/img/edit.svg);
      background-size: 26px;
      right: 45px;
    }
    .collapse_button{
      height: 42px;
      width: 42px;
      right: -16px;
      top: -16px;
      padding: 0px;
      background-image: url(../../public/img/arr_up.svg);
      transition: .2s;
      background-color: #fff;
    }
    .section_bg,.section_no_bg{
      position: relative;
      max-height: 400px;
    }
    .section_bg{
      aspect-ratio: 16/9;
      width: 100%;
      border-radius: 11px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: all 0.3s ease-out;
    }
    .section_no_bg{
      aspect-ratio: 16/9;
      border-radius: 11px;
      background: #F2F8F5;
      transition: all 0.3s ease-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p{
        height: auto;
        text-align: center;
        font-size: 1.29rem;
        line-height: 1.77rem;
        max-height: 3.6rem;
        font-weight: 600;
        margin: 0px;
        box-sizing: border-box;
        padding: 0 10px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
    .status{
      color: #fff;
      padding: 0 16px;
      font-size: 0.76rem;
      text-transform: uppercase;
      display: inline-block;
      line-height: 32px;
      height: 32px;
      border-radius: 8px;
      position: absolute;
      left: 10px;
      top: 10px;
    }
    >h3{
      font-weight: 500;
      margin-bottom: 8px;
      margin-top: 20px;
      color: #252525;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    >p{
      line-height: 24px;
      font-size: 0.76rem;
      font-weight: 400;
      color: #787878;
      margin: 0;
      max-height: 47px;
      min-height: 47px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .media_data{
      padding: 1px 3px;
      margin: 0px;
      position: absolute;
      right: 8px;
      bottom: 10px;
      background-color: #2C2F3596;
      border-radius: 13px;
      height: 26px;
      transition: all .3s;
      box-sizing: border-box;
      li{
        list-style-type: none;
        display: inline-block;
        margin: 0 3px;
        height: 26px;
        width: 22px;
        svg{
          path{
            transition: all .3s;
            fill: #fff;
          }
        }
      }
    }
  }
  .collapsed{
    .media_data{
      background-color: #fff;
      li{
        svg{
          path{
            fill: #787878;
          }
        }
      }
    }
  }
  .collapsed{
    transition: all .2s;
    min-height: 148px;
    .section_bg,.section_no_bg{
      aspect-ratio: auto;
      max-height: 0px;
      p{
        transition: all .3s ease-in-out;
        opacity: 0;
        padding: 0px;
        margin-top: 35px;
      }
    }
    h3{
      transition: all .3s ease-in-out;
      margin-top: 0px;
    }
    ul,.status{
      top: auto;
    }
    ul{
      bottom: -104px !important;
      right: 0px;
      background-color: rgba(0,0,0,0);
      transition: all .3s ease-in-out;
      li{
        svg{
          path{
            fill: #787878;
          }
        }
      }
    }
    .status{
      transition: all .3s ease-in-out;
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      left: 0px;
      bottom: -100px !important;
    }
    .dragger,.collapse_button,.delete_button,.edit_book_button{
      height: 36px;
    }
    .collapse_button{
      transform: rotate(180deg);
    }
  }
  .menu_item:hover{
    cursor: pointer;
  }
  .draggable{
    padding-top: 0px;
    margin-right: 0px;
    margin-left: 44px;
    margin-bottom: 16px;
    .collapse_button{
      top: -45px;
      background-color: rgba(0,0,0,0);
    }
  }
  .draggable.collapsed{
    .collapse_button{
      top: -35px;
    }
  }
  .dragger{
    cursor: grab;
    background-image: url(../../public/img/book/dragger.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 46px;
    width: 120px;
    margin: 0 auto;
  }
  // .books_amount{
  //   position: absolute;
  //   font-size: 14px;
  //   right: 40px;
  //   top: 80px;
  //   color: #787878;
  //   line-height: 30px;
  //   height: 30px;
  //   padding-left: 20px;
  //   cursor: pointer;
  // }
  .side-slide-enter-active,
  .side-slide-leave-active,
  .top-slide-enter-active,
  .top-slide-leave-active,
  .slide-updown-leave-active,
  .slide-updown-enter-active,
  .slide-fade-enter-active,
  .slide-fade-leave-active,
  .category_list-enter-active,
  .category_list-leave-active{
    transition: all 0.3s ease-out;
  }
  .category_list-enter-from,
  .category_list-leave-to{
    max-height: 0px;
  }
  .side-slide-enter-from,
  .side-slide-leave-to {
    margin-right: -40px;
  }
  .top-slide-enter-from,
  .top-slide-leave-to {
    margin-top: -40px;
  }
  .slide-updown-enter-from,
  .slide-updown-leave-to {
    margin-top: -50px;
  }
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    margin-bottom: -120px;
  }
@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1368px)
and (min-height: 900px) and (orientation: portrait){
  .add_book_alone{
    max-height: 300px;
    margin-bottom: 16px;
    margin-right: 16px;
  }
  .add_book{
    left: -77px;
    height: 35px;
    line-height: 35px;
  }
  .add_book_bottom{
    right: -77px;
    height: 35px;
    line-height: 35px;
  }
  .wierd_class{
    right: auto;
    top: auto;
    left: -53px;
    bottom: -80px;
  }
  .menu_item{
    margin-right: 0;
    flex: 0 0 49%;
  }
  
  .draggable{
    margin-left: 35px;
  }
  .menu_item.draggable{
    margin-right: 0;
    margin-bottom: 16px;
    max-width: calc(50% - 35px);
    min-width: auto;
    width: auto;
  }
  .wierd_class{
    bottom: -50px;
  }
  .dropzone{
    height: 400px;
  }
  .book_edit{
    right: 32px;
    left: 32px;
    top: 20%;
    margin-left: 10px;
    width: auto;
  }
  .books{
    .ghost{
      justify-content: space-between;
    }
  }
}
.wierd_class{
  right: auto;
  top: auto;
  left: -30px;
  bottom: -80px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   left: -65px;
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27 00px){
  @media screen and (min-width: 1025px){
    left: -50px;
  }
  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    left: -50px;
  }
  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    left: -50px;
  }
}
@media screen and (max-height: 1024px) and (min-height: 600px) and (max-width: 1368px)
and (min-width: 1025px) and (orientation: landscape){
  .dropzone{
    height: 400px;
  }
  .add_book,.add_book_alone{
    height: 40px;
    line-height: 40px;
  }
  .add_book{
    left: -85px;
  }
  .add_book_bottom{
    right: -85px;
  }
  .book_edit{
    right: 32px;
    left: 32px;
    top: 20%;
    margin-left: 10px;
    width: auto;
  }
  .books{
    .ghost{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
    }
  }
  .books{
    .ghost{
      justify-content: flex-start;
    }
  }
  .draggable{
    margin-left: 40px;
  }
  .menu_item{
    margin-bottom: 16px;
    flex: 0 0 31%;
    margin-right: 24px;
  }
  .menu_item.draggable{
    margin-bottom: 16px;
    flex: 0 0 calc(33% - 40px);
    min-width: none;
    margin-right: 0px;
  }
}
@media screen and (max-height: 1024px) and (min-height: 600px) and (max-width: 1024px)
and (min-width: 912px) and (orientation: landscape){
  .add_book{
    left: -82px;
  }
  .add_book_bottom{
    right: -82px;
  }
}
@media screen and (height: 768px) and (width: 1024px){
  .books{
    .ghost{
      justify-content: flex-start;
    }
  }
  .menu_item{
    flex: 0 0 32%;
    margin-right: 12px;
  }
  .draggable{
    margin-left: 40px;
  }
  .menu_item.draggable{
    margin-right: 0;
    margin-bottom: 16px;
    flex: 0 0 29%;
    min-width: none;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape){
  .add_book_alone{
    max-width: 100%;
    max-height: 150px;
    margin-bottom: 30px;
    margin-right: 0px;
    padding: 0px;
    div{
      border: none;
      .plus{
        display: none;
      }
      span{
        bottom: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
  .book_edit{
    padding: 16px;
    right: 16px;
    left: 16px;
    top: 20%;
    margin-left: 0;
    width: auto;
    .section_title,.section_icon{
      display: block;
      margin-top: 0px;
      p{
        width: 100%;
        font-size: 14px;
      }
      input{
        width: 100%;
      }
    }
  }
  .books_save,.books_cancel{
    top: 9px;
  }
  .menu_item{
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    flex: 0 0 100%;
    .media_data{
      position: absolute;
      bottom: 8px;
      right: 8px;
      top: auto;
      transition: all .3s;
    }
    h3{
      margin-bottom: 2px;
    }
  }
  .menu_item.draggable{
    margin-bottom: 0px;
    margin-left: 0px;
    margin-top: 44px;
    .collapse_button{
      background-color: rgba(0,0,0,0);
      right: -10px;
      top: -46px;
    }
    .add_book{
      top: -44px;
      left: 50%;
      transform: translateX(-50%);
    }
    .add_book_bottom{
      top: auto;
      bottom: -34px;
      left: 50%;
      transform: translateX(-50%);
      right: auto;
    }
  }
  .menu_item.draggable.collapsed{
    .collapse_button{
      right: -10px;
      top: -36px;
    }
    .status{
      bottom: -99px !important;
    }
    .media_data{
      bottom: -102px !important;
    }
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape){
  .active_info{
    bottom: 0px !important;
  }
  .draggable{
    padding-top: 0px;
  }
  .delete_book{
    .trash_can{
      height: 100px;
      background-size: contain;
      margin: 36px 0 16px;
    }
  }
  .delete_book{
    position: fixed;
    box-sizing: border-box;
    top: 10%;
    z-index: 3;
  }
}
// @media screen and (max-height: 500px) and (orientation: landscape){
//   .menu_item{
//     flex: 0 0 46%;
//   }
//   .delete_book{
//     .trash_can{
//       height: 100px;
//       background-size: contain;
//       margin: 16px 0;
//     }
//   }
//   .delete_book{
//     position: fixed;
//     box-sizing: border-box;
//     border-radius: 16px;
//     top: 5%;
//     z-index: 3;
//     left: 15%;
//     margin-left: 0px;
//     width: 70%;
//     max-width: 70%;
//   }
// }
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  box-sizing: border-box;
}
</style>
